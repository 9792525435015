<template>
  <div>
    <v-expansion-panels class="mb-6">
      <!-- Assign Details -->
      <AssignDetails
        v-if="caseDetails.assignDetails"
        :caseDetails="caseDetails"
      />
      <ResolveDetails
        v-if="caseDetails.resolveDetails"
        :caseDetails="caseDetails"
        @stepper="winStepper"
      />
      <ReleaseDetails
        v-if="caseDetails.releaseDetails"
        :caseDetails="caseDetails"
        @stepper="winStepper"
      />
      <SendToPublicDetails
        v-if="caseDetails.sentToPublicDetails"
        :caseDetails="caseDetails"
      />
      <MergeDetails :caseDetails="caseDetails" />
      <CloseCaseDetails
        v-if="caseDetails.closeDetails"
        :caseDetails="caseDetails"
         @stepper="winStepper"
      />
      <v-layout wrap v-if="caseDetails.locationUpdates">
        <v-flex xs12>
          <template v-for="(item, i) in caseDetails.locationUpdates">
            <v-layout wrap v-if="i>0" :key="i">
              <UpdateCases :caseDetails="caseDetails" :storage="item" />
            </v-layout>
          </template>
        </v-flex>
      </v-layout>
    </v-expansion-panels>
  </div>
</template>
<script>
import AssignDetails from "./assign";
import ResolveDetails from "./capture";
import ReleaseDetails from "./resolve";
import SendToPublicDetails from "./sendToPublic";
import MergeDetails from "./merge";
import CloseCaseDetails from "./closeCase";
import UpdateCases from "./updateCases";

export default {
  props: ["caseDetails"],
  components: {
    AssignDetails,
    ResolveDetails,
    ReleaseDetails,
    SendToPublicDetails,
    MergeDetails,
    CloseCaseDetails,
    UpdateCases,
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "assignFunction") {
        this.assign(window_data.assignType, window_data.officerId);
      } else if (window_data.type == "reportActions") {
        this.$emit("stepper", {
          type: window_data.type,
        });
      }
    },
  },
};
</script>