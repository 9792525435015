<template>
  <v-expansion-panel v-if="caseDetails.isMain">
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span class="itemHeading">Merged Reports</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <!-- Assigned By -->
      <v-layout wrap pt-2>
        <v-flex xs12 v-if="caseDetails.mergedReports.length > 0">
          <v-layout
            py-2
            wrap
            v-for="item in caseDetails.mergedReports"
            :key="item._id"
          >
            <v-flex
              xs3
              sm2
              md3
              lg2
              xl2
              :class="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? 'text-center'
                  : 'text-left'
              "
              align-self-center
            >
              <v-avatar size="62px" v-if="item && item.reportImages && item.reportImages.length > 0">
                <img alt="Avatar" :src="mediaURL + item.reportImages[0]" />
              </v-avatar>
              <v-avatar v-else size="62px">
              <img alt="Avatar" src="../../../assets/images/noimage.jpg" />
            </v-avatar>
            </v-flex>
            <v-flex xs8 sm7 md9 lg9 xl9>
              <v-layout wrap>
                <v-flex xs6 sm3 md4>
                  <!-- <v-btn
                    x-small
                    depressed
                    class="py-2"
                    :outlined="item.status == 'Pending' ? true : false"
                    :ripple="false"
                    :color="item.status == 'Pending' ? '#deb710' : '#F5F5F5'"
                    style="letter-spacing: 0px"
                    ><span
                      style="
                        font-family: sofiaProRegular;
                        color: #000;
                        font-size: 10px;
                      "
                      >RESPONSE NEEDED
                    </span></v-btn
                  > -->
                </v-flex>
                <v-flex xs4 sm3 md4 text-left>
                  <span
                    class="text-uppercase"
                    style="
                      font-family: sofiaProRegular;
                      color: #deb710;
                      font-size: 10px;
                    "
                  >
                    {{ item.statuscopy }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs8 sm7 md8 lg8 xl8>
                  <span class="itemHeading">
                    {{ item.animalId.name }}
                  </span>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4 xl4 text-right>
                  <span
                    style="
                      font-family: sofiaProRegular;
                      color: #000;
                      font-size: 10px;
                    "
                  >
                    {{ timeSince(item.create_date) }}
                    <!-- <timeago :datetime="item.create_date" :auto-update="60"></timeago> -->
                  </span>
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: sofiaProRegular;
                      color: #626262;
                      font-size: 12px;
                    "
                  >
                    Threat Level:
                    <span v-if="item.threatlevel">
                      {{ formatText(item.threatlevel) }}</span
                    >
                    <span v-else>Null</span>
                  </span>
                  <br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      color: #626262;
                      font-size: 12px;
                    "
                  >
                    Location Name:
                    <span v-if="item.locationname">{{
                      formatText(item.locationname)
                    }}</span>
                    <span v-else>Null</span>
                  </span>
                  <br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      color: #626262;
                      font-size: 12px;
                    "
                  >
                    Reported Date & Time:
                    <span v-if="item.reportTime">{{
                      formatReportTime(item.reportTime)
                    }}</span>
                    <span v-else>Null</span>
                  </span>
                  <br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      color: #626262;
                      font-size: 12px;
                    "
                    v-if="item.addedBy.role"
                  >
                    Sighted by:
                    <span v-if="item.addedBy.role === 'User'">
                      <span>{{ item.addedBy.userId.name }}</span>
                    </span>
                    <span v-if="item.addedBy.role === 'MASTER'">
                      <span>{{ item.addedBy.admin.masterName }}</span>
                    </span>
                    <span v-if="item.addedBy.role === 'EmergencyUser'">
                      <span>{{ item.addedBy.userId.name }}</span>
                    </span>
                    <span v-if="item.addedBy.role === 'RANGE'">
                      <span v-if="item.addedBy.range.accessCredentials">{{
                        item.addedBy.range.accessCredentials.username
                      }}</span>
                    </span>
                    <span v-if="item.addedBy.role === 'DIVISION'">
                      <span v-if="item.addedBy.division.accessCredentials">{{
                        item.addedBy.division.accessCredentials.username
                      }}</span>
                    </span>
                    <span v-if="item.addedBy.role === 'RRT'">
                      <span v-if="item.addedBy.userId.name">{{
                        item.addedBy.userId.name
                      }}</span>
                    </span>
                  </span>
                </v-flex>
                <!-- <v-flex xs12 sm9 md12 lg12 xl2 pt-1>
                <v-btn
                  width="100%"
                  shaped
                  small
                  color="#000000"
                  @click="$router.push('/Admin/cases/caseDetails/?id=' + item._id) && checkSound(item)"
                >
                  <span
                    v-if="item.status == 'Pending'"
                    style="color: #fff; font-size: 10px"
                    >Take Action
                  </span>
                  <span v-else style="color: #fff; font-size: 10px"
                    >View Details
                  </span>
                  <v-icon
                    style="
                      font-family: sofiaProRegular;
                      color: #ffffff;
                      font-size: 14px;
                    "
                    class="pl-3"
                    >mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-flex> -->
              </v-layout>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs12 pt-2>
              <v-layout py-2 wrap>
                <v-flex xs2>
                  <v-avatar size="40px">
                    <!-- <img alt="Avatar" :src="mediaURL + item.mergedBy.photo" /> -->
                  </v-avatar>
                </v-flex>
                <v-flex xs7>
                  <v-layout wrap>
                    <v-flex xs12>
                      <span class="itemHeading">Merged By</span><br />
                      <span
                        style="
                          font-family: sofiaProRegular;
                          color: #626262;
                          font-size: 12px;
                        "
                      >
                        <span
                          style="
                            font-family: sofiaProRegular;
                            color: #626262;
                            font-size: 12px;
                          "
                          v-if="item && item.mergedBy && item.mergedBy.role"
                        >
                          
                          <span v-if="item.mergedBy.role === 'User'">
                            <span>{{ item.mergedBy.userId.name }}</span>
                          </span>
                          <span v-if="item.mergedBy.role === 'MASTER'">
                            <span>{{ item.mergedBy.admin.masterName }}</span>
                          </span>
                          <span v-if="item.mergedBy.role === 'EmergencyUser'">
                            <span>{{ item.mergedBy.userId.name }}</span>
                          </span>
                          <span v-if="item.mergedBy.role === 'RANGE'">
                            <span v-if="item.mergedBy.range.accessCredentials">{{
                              item.mergedBy.range.accessCredentials.username
                            }}</span>
                          </span>
                          <span v-if="item.mergedBy.role === 'DIVISION'">
                            <span
                              v-if="item.mergedBy.division.accessCredentials"
                              >{{
                                item.mergedBy.division.accessCredentials.username
                              }}</span
                            >
                          </span>
                          <span v-if="item.mergedBy.role === 'RRT'">
                            <span v-if="item.mergedBy.userId.name">{{
                              item.mergedBy.userId.name
                            }}</span>
                          </span>
                        </span>
                      </span>
                      <br />
                      <!-- <span
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                        "
                      >
                        {{ item.mergedBy.phone }}
                      </span> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 :key="i" pt-4>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  props: ["caseDetails"],
  data() {
    return {};
  },

  methods: {
    formatText(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    formatReportTime(dateString) {
      const date = new Date(dateString);
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      });
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      });
      return `${time} ${formattedDate}`;
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>