<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap>
      <v-flex xs12>
        <v-card
          @click="confirmPublicDialog = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Send To Public
          </span>
        </v-card>

        <!-- Confirmation Dialog for "Send To Public" -->
        <v-dialog v-model="confirmPublicDialog" max-width="500">
          <v-card>
            <v-card-title class="headline"> Confirmation </v-card-title>
            <v-card-text>
              <span
                >Whether the case should be visible to users in nearby
                conflicts?</span
              >
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                small
                text
                @click="confirmPublicDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="appColor"
                small
                text
                @click="confirmPublicVisibility"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Existing Assign to RRT Dialog -->
        <v-dialog v-model="assignDialogue1" :width="dialogWidth">
          <!-- Existing content of assignDialogue1 -->
        </v-dialog>

        <!-- Existing Case Assigned Dialog -->
        <v-dialog v-model="assigningItem.assignDialogue2" :width="dialogWidth">
          <!-- Existing content of assignDialogue2 -->
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  props: ["caseDetails", "createDate"],
  data() {
    return {
      assignDialogue1: false,
      confirmPublicDialog: false, // New data property for confirmation dialog
      assignDialogue2: false,
      officials: [],
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      assigningItem: {},
      rrtname: "",
    };
  },
  computed: {
    dialogWidth() {
      return this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "md"
        ? "100vw"
        : this.$vuetify.breakpoint.name === "lg"
        ? "50vw"
        : "50vw";
    },
  },
  mounted() {
    this.getOfficials();
  },
  methods: {
    assign(assignType, officerId) {
      this.assignDialogue1 = false;
      this.assignDialogue2 = false;
      this.$emit("stepper", {
        type: "assignFunction",
        assignType: assignType,
        officerId: officerId,
      });
    },
    getOfficials() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rrt/getlistdropdown/forassign",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status === true) {
            this.officials = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    confirmPublicVisibility() {
      axios({
        method: "POST",
        url: "/report/check/issentopublic",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          reportId: this.caseDetails._id,
          isSentToPublic: true,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          if (response.data.status === true) {
            this.confirmPublicDialog = false;
            setInterval(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
  