<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 v-if="appUser === 'EmergencyUser' || appUser === 'range' || appUser === 'division'|| appUser === 'RRT'">
        <v-card 
        @click="$router.push({ path: '/officers/resolveCase/', query: { id: caseDetails._id, name: caseDetails.animalId.name, location: caseDetails.locationname, date: caseDetails.reportTime, animalid: caseDetails.animalId._id } })"

          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Resolve
          </span>
        </v-card>
      </v-flex>
      <v-flex xs12 v-if="appUser === 'admin'">
        <v-card 
        @click="$router.push({ path: '/Admin/resolveCase/', query: { id: caseDetails._id, name: caseDetails.animalId.name, location: caseDetails.locationname, date: caseDetails.reportTime, animalid: caseDetails.animalId._id  } })"

          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Resolve
          </span>
        </v-card>
        <!-- <v-card
          @click="closeDialogue = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Resolve
          </span>
        </v-card> -->
        <!-- <v-dialog
          v-model="closeDialogue"
          persistent
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '60vw'
              : '50vw'
          "
        >
          <v-card flat>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs12>
                <span style="font-size: 24px" class="pl-2 itemSubHeading">
                  Resolve</span
                >
              </v-flex>
              <v-flex xs12>
                <span style="font-size: 13px" class="pl-2 formClass">
                  Resolve the case by filling the below form.</span
                >
              </v-flex>
              <v-flex xs12 pt-4>
                <v-form @submit.prevent="validateInput">
                  <v-textField
                    outlined
                    dense
                    color="#B1B1B1"
                    co
                    class="formClass"
                    v-model="resolveData.address"
                    placeholder="Resolve Location"
                    label="Resolve Location"
                    :rules="[rules.required]"
                  >
                  </v-textField>
                  <v-select
                    v-model="resolveData.mitigationTypeId"
                    :items="mitigationTypes"
                    item-text="name"
                    item-value="_id"
                    class="formClass"
                    color="#B1B1B1"
                    outlined
                    dense
                    placeholder="Mitigation Types"
                    label="Mitigation Types"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-if="
                      resolveData.mitigationTypeId == '60ab7c4d4445a8527c3a2967'
                    "
                    v-model="resolveData.mitigationMethodId"
                    :items="mitigationMethods"
                    item-text="name"
                    item-value="_id"
                    class="formClass"
                    color="#B1B1B1"
                    outlined
                    dense
                    placeholder="Mitigation Method"
                    label="Mitigation Method"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-textField
                    outlined
                    color="#B1B1B1"
                    dense
                    class="formClass"
                    type="number"
                    v-model="resolveData.animal_count"
                    placeholder="Number of Animals"
                    label="Number of Animals"
                    :rules="[rules.required]"
                  >
                  </v-textField>

                  <v-select
                    v-model="resolveData.animalActivityId"
                    placeholder="Activity of Animals"
                    label="Activity of Animals"
                    :items="animalActivities"
                    item-text="name"
                    item-value="_id"
                    class="formClass"
                    color="#B1B1B1"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-model="resolveData.animalStatusId"
                    placeholder="Status of Animals"
                    label="Status of Animals"
                    :items="animalStatus"
                    item-text="name"
                    item-value="_id"
                    class="formClass"
                    color="#B1B1B1"
                    hide-details=""
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-select>
                  <v-layout>
                    <v-checkbox
                      v-if="
                        resolveData.animalStatusId == '60ab7fbc5c44d553c8027a33'
                      "
                      v-model="resolveData.isInjuryCausedByPublic"
                      label="Is any Injury Caused by public?"
                      :color="appColor"
                      class="formClass"
                      dense
                      :value="true"
                      :ripple="false"
                      hide-details
                    ></v-checkbox>
                  </v-layout>
                  <v-layout pt-3 pb-5>
                    <v-checkbox
                      v-model="resolveData.isVeterinaryPresent"
                      label="Is veterinary present?"
                      :color="appColor"
                      class="formClass"
                      dense
                      :value="true"
                      :ripple="false"
                      hide-details
                    ></v-checkbox>
                  </v-layout>

                  <v-layout
                    wrap
                    v-if="
                      resolveData.mitigationTypeId ==
                        '60ab7c4d4445a8527c3a2967' &&
                      resolveData.isVeterinaryPresent
                    "
                  >
                    <v-flex xs12>
                      <v-select
                        v-model="resolveData.animalAgeId"
                        placeholder="Age of Animal"
                        label="Age of Animal"
                        :items="animalAge"
                        item-text="name"
                        item-value="_id"
                        class="formClass"
                        color="#B1B1B1"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="resolveData.veterinaryRecommendationId"
                        placeholder="Veterinary Reccomendation"
                        label="Veterinary Reccomendation"
                        :items="VeterinaryReccomendations"
                        item-text="name"
                        item-value="_id"
                        class="formClass"
                        color="#B1B1B1"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="resolveData.genderId"
                        placeholder="Gender of Animal"
                        label="Gender of Animal"
                        :items="gender"
                        item-text="name"
                        item-value="_id"
                        class="formClass"
                        color="#B1B1B1"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-select
                    v-model="resolveData.landTypeId"
                    placeholder="Land Type"
                    label="Land Type"
                    :items="landTypes"
                    item-text="name"
                    item-value="_id"
                    class="formClass"
                    color="#B1B1B1"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-select>

                  <v-textField
                    v-if="resolveData.landTypeId == '60a7ff750e4d4a53dca5cff6'"
                    outlined
                    color="#B1B1B1"
                    dense
                    class="formClass"
                    v-model="resolveData.cropCultivated"
                    placeholder="Crop Cultivated"
                    label="Crop Cultivated"
                    :rules="[rules.required]"
                  >
                  </v-textField>
                  <v-textarea
                    rows="4"
                    outlined
                    v-model="resolveData.remarks"
                    color="#B1B1B1"
                    class="formClass"
                    hide-details
                    placeholder="Remarks"
                    label="Remarks"
                  ></v-textarea>
                  <v-layout wrap py-6>
                    <v-flex xs6>
                      <v-checkbox
                        v-model="resolveData.ishumanInjury_humanDeath"
                        label="Is Human Injury/Death Occurs"
                        :color="appColor"
                        dense
                        class="formClass"
                        :value="true"
                        :ripple="false"
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs6>
                      <v-checkbox
                        v-model="resolveData.isanimalInjury_animalDeath"
                        label="Is Animal Injury/Death Occurs"
                        :color="appColor"
                        dense
                        class="formClass"
                        :value="true"
                        :ripple="false"
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="resolveData.ishumanInjury_humanDeath">
                    <v-flex xs12>
                      <v-textField
                        outlined
                        color="#B1B1B1"
                        dense
                        class="formClass"
                        type="number"
                        v-model="resolveData.humanInjury_count"
                        placeholder="Human Injury Count"
                        label="Human Injury Count"
                      >
                      </v-textField>
                    </v-flex>
                    <v-flex xs12>
                      <v-textField
                        outlined
                        color="#B1B1B1"
                        dense
                        class="formClass"
                        type="number"
                        v-model="resolveData.humanDeath_count"
                        placeholder="Human Death Count"
                        label="Human Death Count"
                      >
                      </v-textField>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="resolveData.isanimalInjury_animalDeath">
                    <v-flex xs12>
                      <v-textField
                        outlined
                        color="#B1B1B1"
                        dense
                        class="formClass"
                        type="number"
                        v-model="resolveData.animalDeath_count"
                        placeholder="Animal Death Count"
                        label="Animal Death Count"
                      >
                      </v-textField>
                    </v-flex>
                    <v-flex xs12>
                      <v-textField
                        outlined
                        color="#B1B1B1"
                        dense
                        class="formClass"
                        type="number"
                        v-model="resolveData.animalInjury_count"
                        placeholder="Animal Injury Count"
                        label="Animal Injury Count"
                      >
                      </v-textField>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pb-4>
                    <v-flex xs12 pb-2>
                      <span style="font-size: 14px" class="pl-2 formClass">
                        Please Upload Images</span
                      >
                    </v-flex>
                    <v-flex md12 xs12>
                      <ImageComp
                        @stepper="winStepper"
                        :height="'800'"
                        :width="'600'"
                        :heading="'Upload Images'"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
              <v-flex xs3>
                <v-btn
                  block
                  small
                  color="#FF6347"
                  dark
                  :ripple="false"
                  depressed
                  type="submit"
                  @click="closeDialogue = false"
                >
                  <span class="text-capitalize" style=""> CANCEL </span>
                </v-btn>
              </v-flex>
              <v-flex xs3 pl-3>
                <v-btn
                  block
                  small
                  :color="appColor"
                  dark
                  :ripple="false"
                  depressed
                  type="submit"
                  @click="validateInput()"
                >
                  <span class="text-capitalize" style=""> RESOLVE CASE </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import ImageComp from "@/components/Common/multipleImages";
// import resolveapiCalls from "./resolve";

export default {
  props: ["caseDetails", "createDate"],
  components: {
    // ImageComp,
  },
  data() {
    return {
      closeDialogue: false,
      imageDialogue: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      rules: {
        required: (value) => !!value || "Required",
      },
      resolveData: {
        reportId: null,
        address: null,
        landTypeId: null,
        animal_count: null,
        animalActivityId: null,
        remarks: null,
        isVeterinaryPresent: null,
        mitigationTypeId: null,
        mitigationMethodId: null,
        veterinaryRecommendationId: null,
        gender: null,
        genderId: null,
        animalStatusId: null,
        isInjuryCausedByPublic: null,
        ishumanInjury_humanDeath: null,
        humanInjury_count: null,
        humanDeath_count: null,
        isanimalInjury_animalDeath: null,
        animalInjury_count: null,
        animalDeath_count: null,
        animalAgeId: null,
      },
      mitigationTypes: [],
      animalActivities: [],
      landTypes: [],
      anjimalAGe: [],
      animalStatus: [],
      gender: [],
      VeterinaryReccomendations: [],
      imageFiles: [],
      imageArray: [],
      formData: new FormData(),
      mitigationMethods: [],
    };
  },
  computed: {
    form() {
      return {
        address: this.resolveData.address,
        landTypeId: this.resolveData.landTypeId,
        animal_count: this.resolveData.animal_count,
        remarks: this.resolveData.remarks,
        mitigationTypeId: this.resolveData.mitigationTypeId,
        animalActivityId: this.resolveData.animalActivityId,
      };
    },
    appUser() {
      return this.$store.state.userRole;
    },
  },
  mounted() {
    this.getMitigationTypes();
    this.getAnimalActivities();
    this.getLandTypes();
    this.getAnimalAge();
    this.getAnimalStatus();
    this.getGender();
    this.getVeterinaryReccomendations();
  },
  watch: {
    resolveData: {
      handler(val) {
        for (var i = 0; i < this.mitigationTypes.length; i++) {
          if (val.mitigationTypeId == this.mitigationTypes[i]._id) {
            this.mitigationMethods = this.mitigationTypes[i].mitigationMethods;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
        console.log("gjjjjj", this.imageFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
        this.imageFiles.splice(window_data.removalItem, 1);
      }
    },
    //   navigateToPage(caseDetails) {
    //   this.$router.push({ path: '/Admin/resolveCase',query: {
    //     caseDetails: caseDetails._id,
    //   } });
    // },
    validateInput() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        // if (!this.$refs[f].validate(true)) {
        //   this.msg = "Please Provide Valid " + f;
        //   this.showSnackBar = true;
        //   this.formHasErrors = true;
        //   return;
        // }
      });
      if (this.formHasErrors == false) this.resolveCase();
    },
    getMitigationTypes() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/mitigationType/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.mitigationTypes = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getLandTypes() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/landType/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.landTypes = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getAnimalActivities() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/animalActivity/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.animalActivities = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getAnimalStatus() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/animalStatus/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.animalStatus = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getAnimalAge() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/animalAge/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.animalAge = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getVeterinaryReccomendations() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/veterinaryRecommendation/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.VeterinaryReccomendations = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getGender() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/gender/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.gender = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    resolveCase() {
      this.closeDialogue = false;
      this.appLoading = true;
      this.resolveData.reportId = this.caseDetails._id;

      axios({
        method: "POST",
        url: "/report/resolve",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.resolveData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.$emit("stepper", {
            //   type: "reportActions",
            //   pageResponse: response.data,
            // });
            Object.keys(this.resolveData).forEach(
              (key) => (this.resolveData[key] = null)
            );
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.validateImage();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    validateImage() {
      if (!this.imageFiles.length > 1) {
        this.msg = "Please Upload Image";
        this.showSnackBar = true;
        return;
      } else if (this.imageArray.length > 0) {
        for (var i = 0; i < this.imageFiles.length; i++)
          this.formData.append("resolveImages", this.imageFiles[i]);
        this.uploadMainImages(this.caseDetails._id);
      }
    },
    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("reportId", item);
      axios({
        method: "POST",
        url: "/report/resolve/imageUpload",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.imageDialogue = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$emit("stepper", {
              type: "reportActions",
              pageResponse: response.data,
            });
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showSnackBar = true;
            this.$emit("stepper", {
              type: "reportActions",
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showSnackBar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>