<template>
  <v-expansion-panel v-if="caseDetails.sentToPublicDetails.sentToPublicStatus">
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span class="itemHeading">Send to Public Details</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <!-- Assigned By -->
      <v-layout py-2 wrap>
        <v-flex xs2>
          <v-avatar v-if="caseDetails.sentToPublicDetails.sentToPublicBy" size="40px">
            <img
              alt="Avatar"
              :src="
                mediaURL + caseDetails.sentToPublicDetails.sentToPublicBy.photo
              "
            />
          </v-avatar>
        </v-flex>
        <v-flex xs7>
          <v-layout wrap>
            <v-flex xs12>
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 13px;
                  color: #4d4d4d;
                "
                >Send to Public By</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
              >
                <span
                  v-if="caseDetails.sentToPublicDetails.sentToPublicBy "
                  >{{
                    caseDetails.sentToPublicDetails.sentToPublicBy.fullName
                  }}</span
                >
                <span v-else>UnKnown </span>
              </span>
              <br />
              <span
                  v-if="caseDetails.sentToPublicDetails.sentToPublicBy "

                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                "
              >
                {{ caseDetails.sentToPublicDetails.sentToPublicBy.phone }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex
          xs12
          v-if="caseDetails.sentToPublicDetails.offlinePublic.length > 0"
        >
          <span
            style="font-family: sofiaProLight; font-size: 13px; color: #4d4d4d"
            >Offline Public Details</span
          ><br />
          <v-layout
            py-2
            wrap
            v-for="item in caseDetails.sentToPublicDetails.offlinePublic"
            :key="item._id"
          >
            <v-flex xs2>
              <v-avatar size="40px">
                <img alt="Avatar" :src="mediaURL + item.photo" />
              </v-avatar>
            </v-flex>
            <v-flex xs7>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: sofiaProRegular;
                      font-size: 12px;
                      color: #000;
                      line-height: 0.5;
                    "
                  >
                    <span v-if="item.fullName">{{ item.fullName }}</span>
                    <span v-else>UnKnown </span>
                  </span>
                  <br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      font-size: 12px;
                      color: #000;
                    "
                  >
                    {{ item.phone }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <span
                class="float-right"
                v-if="caseDetails.sentToPublicDetails.sentToPublicTime"
                style="
                  font-family: sofiaProRegular;
                  color: #000;
                  font-size: 10px;
                "
              >
                {{
                  timeSince(caseDetails.sentToPublicDetails.sentToPublicTime)
                }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout wrap pt-2>
        <v-flex
          xs12
          v-if="caseDetails.sentToPublicDetails.activePublic.length > 0"
        >
          <span
            style="font-family: sofiaProLight; font-size: 13px; color: #4d4d4d"
            >Online Public Details</span
          ><br />
          <v-layout
            py-2
            wrap
            v-for="item in caseDetails.sentToPublicDetails.activePublic"
            :key="item._id"
          >
            <v-flex xs2>
              <v-avatar size="40px">
                <img alt="Avatar" :src="mediaURL + item.photo" />
              </v-avatar>
            </v-flex>
            <v-flex xs7>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: sofiaProRegular;
                      font-size: 12px;
                      color: #000;
                      line-height: 0.5;
                    "
                  >
                    <span v-if="item.fullName">{{ item.fullName }}</span>
                    <span v-else>UnKnown </span>
                  </span>
                  <br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      font-size: 12px;
                      color: #000;
                    "
                  >
                    {{ item.phone }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  props: ["caseDetails"],
  data() {
    return {};
  },

  methods: {
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>