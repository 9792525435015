<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 v-if="appUser === 'EmergencyUser' || appUser === 'range' || appUser === 'division' || appUser === 'RRT'">
        <v-card 
        @click="$router.push({ path: '/officers/releaseCase/', query: { id: caseDetails._id, name: caseDetails.animalId.name, location: caseDetails.locationname, date: caseDetails.reportTime, animalid: caseDetails.animalId._id } })"

          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
          Release
          </span>
        </v-card>
      </v-flex>
      <v-flex xs12 v-if="appUser === 'admin'">
        <v-card
        @click="$router.push({ path: '/Admin/releaseCase/', query: { id: caseDetails._id, name: caseDetails.animalId.name, location: caseDetails.locationname, date: caseDetails.reportTime } })"

          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
          Release
          </span>
        </v-card>
        <!-- <v-card
          @click="closeDialogue = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Release
          </span>
        </v-card> -->
        <v-dialog
          v-model="closeDialogue"
          persistent
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '60vw'
              : '50vw'
          "
        >
          <v-card flat>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs12>
                <span style="font-size: 24px" class="pl-2 itemSubHeading">
                  Release</span
                >
              </v-flex>
              <v-flex xs12>
                <span style="font-size: 13px" class="pl-2 formClass">
                  Release the case by filling the below form.</span
                >
              </v-flex>
              <v-flex xs12 pt-4>
                <v-form @submit.prevent="validateInput">
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-textField
                        outlined
                        dense
                        color="#B1B1B1"
                        co
                        class="formClass"
                        v-model="releaseData.address"
                        placeholder="Release Location"
                        :rules="[rules.required]"
                      >
                      </v-textField>
                    </v-flex>
                  </v-layout>

                  <v-flex xs12>
                    <v-select
                      v-model="releaseData.releaseTypeId"
                      :items="releaseTypes"
                      class="formClass"
                      color="#B1B1B1"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      placeholder="Release Type"
                      :rules="[rules.required]"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-select
                      v-if="
                        releaseData.releaseTypeId == '60a7ee0af637aa1ddb5f964a'
                      "
                      v-model="releaseData.handoverUsertype"
                      :items="handOverTypes"
                      class="formClass"
                      color="#B1B1B1"
                      outlined
                      dense
                      @change="loadData(releaseData.handoverUsertype)"
                      item-text="name"
                      item-value="value"
                      placeholder="Handover To"
                      :rules="[rules.required]"
                    >
                    </v-select>
                    <vue-element-loading
                      :active="appLoading1"
                      :is-full-screen="false"
                      background-color="#FFFFFF"
                      :color="appColor"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-if="
                        releaseData.handoverUsertype &&
                        releaseData.releaseTypeId == '60a7ee0af637aa1ddb5f964a'
                      "
                      v-model="releaseData.handoverTo"
                      :items="handoverData"
                      class="formClass"
                      color="#B1B1B1"
                      outlined
                      dense
                      item-text="fullName"
                      item-value="_id"
                      placeholder="Handover To"
                      :rules="[rules.required]"
                      autocomplete
                    ></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-textarea
                      rows="4"
                      outlined
                      v-model="releaseData.remarks"
                      color="#B1B1B1"
                      class="formClass"
                      hide-details
                      placeholder="Remark"
                    ></v-textarea>
                  </v-flex>
                </v-form>
              </v-flex>
              <v-layout pt-4 justify-center>
                <v-flex xs3>
                  <v-btn
                    block
                    small
                    color="#FF6347"
                    dark
                    :ripple="false"
                    depressed
                    type="submit"
                    @click="closeDialogue = false"
                  >
                    <span class="text-capitalize" style=""> CANCEL </span>
                  </v-btn>
                </v-flex>
                <v-flex xs3 pl-3>
                  <v-btn
                    block
                    small
                    :color="appColor"
                    dark
                    :ripple="false"
                    depressed
                    type="submit"
                    @click="validateInput()"
                  >
                    <span class="text-capitalize" style=""> RELEASE CASE </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["caseDetails", "createDate"],
  data() {
    return {
      closeDialogue: false,
      appLoading: false,
      appLoading1: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      rules: {
        required: (value) => !!value || "Required",
      },
      handOverData: [],
      handOverTypes: [
        {
          name: "Division",
          value: "division",
        },
        {
          name: "Range",
          value: "range",
        },
        {
          name: "RRT",
          value: "rrt",
        },
      ],
      releaseData: {
        reportId: null,
        address: null,
        releaseTypeId: null,
        handoverUsertype: null,
        handoverTo: null,
        remarks: null,
      },
      releaseTypes: [],
      temp: "division",
    };
  },
  computed: {
    form() {
      return {
        address: this.releaseData.address,
        remarks: this.releaseData.remarks,
        releaseType: this.releaseData.releaseTypeId,
      };
    },
    appUser() {
      return this.$store.state.userRole;
    },
    myData() {
      return this.handOverData;
    },
  },
  mounted() {
    this.getReleaseTypes();
  },
  methods: {
    loadData(item) {
      this.appLoading1 = true;

      axios({
        method: "POST",
        url: "/handover/officials/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          handoverUsertype: item,
          reportId: this.caseDetails._id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.handoverData = response.data.data;
              setTimeout(function () {}, 10000);
              console.log(this.handoverData, "hand");
              this.appLoading1 = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading1 = false;
          console.log(err);
        });
    },
    validateInput() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        // if (!this.$refs[f].validate(true)) {
        //   this.msg = "Please Provide Valid " + f;
        //   this.showSnackBar = true;
        //   this.formHasErrors = true;
        //   return;
        // }
      });
      if (this.formHasErrors == false) this.releaseCase();
    },
    getReleaseTypes() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/releasetype/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.releaseTypes = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    releaseCase() {
      this.closeDialogue = false;
      this.appLoading = true;
      this.releaseData.reportId = this.caseDetails._id;

      axios({
        method: "POST",
        url: "/report/resolved/release",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.releaseData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.$emit("stepper", {
              type: "reportActions",
              pageResponse: response.data,
            });
            Object.keys(this.releaseData).forEach(
              (key) => (this.releaseData[key] = null)
            );
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>