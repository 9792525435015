<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card
          @click="assignDialogue = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Self Assign
          </span>
        </v-card>
        <v-dialog
          v-model="assignDialogue"
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '35vw'
              : '50vw'
          "
        >
          <v-card>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs9 text-center>
                <v-layout wrap>
                  <v-flex xs11>
                    <v-icon size="35" :color="appColor"
                      >mdi-check-circle-outline</v-icon
                    >
                    <span class="pl-2 itemSubHeading">Self Assigned</span>
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <v-btn
                      x-small
                      depressed
                      dense
                      :ripple="false"
                      color="#EdEdEd"
                      style="letter-spacing: 0px"
                      ><span
                        v-if="caseDetails.animalId"
                        style="
                      font-family: sofiaProRegular;
                      color: #000;
                      font-size: 10px;
                    "
                        >{{ caseDetails.animalId.name }}
                      </span></v-btn
                    >
                  </v-flex>
                  <v-flex xs12 py-2>
                    <span
                      v-if="caseDetails.locationname"
                      style="
                        font-family: sofiaProRegular;
                        font-size: 13px;
                        color: #000;
                      "
                    ><span>Location:</span>
                      {{ caseDetails.locationname }}
                      <!-- Chempara peak, Wayanadu wildlife Sanctuary    -->
                    </span><br>
                    <span
                      v-if="formattedReportDate"
                      style="
                        font-family: sofiaProRegular;
                        font-size: 13px;
                        color: #000;
                      "
                    ><span>Reported Date:</span>
                      {{ formattedReportDate }}
                    </span>
                  </v-flex>
                  
                  <!-- <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs3 offset-2>
                        <v-avatar size="40px">
                          <img
  
                            alt="Wildwatch"
                            :src="mediaURL + caseDetails.userId.photo "
                          /> 
                         
                        </v-avatar>
                      </v-flex>
                      <v-flex xs3 text-left>
                        <span class="smallLightHeading">Sighted by</span><br />
                        <span v-if="caseDetails.userId" class="smallHeading">{{
                          caseDetails.userId.fullName
                        }}</span>
                      </v-flex>
                      <v-flex xs3>
                        <span
                          v-if="caseDetails.create_date"
                          class="smallLightHeading"
                        >
                          {{ createDate }} </span
                        ><br />
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs6 offset-3 pt-4>
                    <v-btn
                      block
                      small
                      :color="appColor"
                      dark
                      :ripple="false"
                      depressed
                      type="submit"
                      @click="assign('self')"
                    >
                      <span class="text-capitalize" style=""> CONTINUE </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["caseDetails", "createDate"],
  data() {
    return {
      assignDialogue: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
    };
  },
  computed: {
    formattedReportDate() {
  if (this.caseDetails.reportTime) {
    const date = new Date(this.caseDetails.reportTime);
    const day = date.getDate().toString().padStart(2, '0'); // Get day and ensure it's 2 digits
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (0-indexed, so add 1) and ensure it's 2 digits
    const year = date.getFullYear(); // Get full year

    return `${day}/${month}/${year}`; // Return formatted date
  }
  return null;
}
  },

  methods: {
    assign(assignType) {
      this.assignDialogue = false;
      this.$emit("stepper", {
        type: "assignFunction",
        assignType: assignType,
      });
    },

    // assign(assignType) {
    //   this.assignDialogue = false;
    //   this.appLoading = true;
    //   var reportData = {};
    //   reportData["reportId"] = this.caseDetails._id;
    //   reportData["assignType"] = assignType;
    //   reportData["userId"] = this.$store.state.userData._id;
    //   //   reportData["userId"] = this.$store.userData.id;

    //   axios({
    //     method: "POST",
    //     url: "/report/assign",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: reportData,
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.$emit("stepper", {
    //           type: "reportActions",
    //           pageResponse: response.data,
    //         });

    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
  },
};
</script>