<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card
          @click="assignDialogue1 = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Assign To
          </span>
        </v-card>
        <v-dialog
          v-model="assignDialogue1"
          p
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '50vw'
              : '50vw'
          "
        >
          <v-card elevation="2" tile>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs12>
                <span style="font-size: 24px" class="itemSubHeading">
                  Assign To</span
                >
              </v-flex>
              <v-flex xs12>
                <span style="font-size: 13px" class="smallLightHeading">
                  Select Officials Near by the Conflict area.</span
                >
              </v-flex>
              <v-flex xs12 pt-4 text-center>
                <v-layout wrap justify-center>
                  <v-flex xs12 lg6 class="smallLightHeading">
                      <v-select dense outlined v-model="rrtname" placeholder="Select RRT" :items="officials" item-text="name" item-value="_id"></v-select>
                  </v-flex>
                  <v-flex xs12 lg4 pt-1 pl-lg-2>
                    <v-btn
                      block
                      small
                      :color="appColor"
                      dark
                      :ripple="false"
                      depressed
                      type="submit"
                      @click="assignToRrt()"
                    >
                      <span class="text-capitalize" style=""> Assign </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="assigningItem.assignDialogue2"
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '35vw'
              : '50vw'
          "
        >
          <v-card elevation="2" tile>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs9 text-center>
                <v-layout wrap>
                  <v-flex xs11>
                    <v-icon size="35" :color="appColor"
                      >mdi-check-circle-outline</v-icon
                    >
                    <span class="pl-2 itemSubHeading">Case Assigned To</span>
                  </v-flex>
                  <v-flex xs12 pt-4>
                    <v-layout wrap>
                      <v-flex xs3 offset-2>
                        <v-avatar size="40px">
                          <img
                            v-if="assigningItem.photo"
                            alt="Wildwatch"
                            :src="mediaURL + assigningItem.photo"
                          />
                          <img
                            v-else
                            alt="Wildwatch 2.0"
                            src="../../../assets/images/tiger.jpg"
                          />
                        </v-avatar>
                      </v-flex>
                      <v-flex xs3 text-left>
                        <span
                          v-if="assigningItem.fullName"
                          style="font-size: 14px"
                          class="smallHeading"
                          >{{ assigningItem.fullName }}</span
                        ><br />
                        <span
                          style="font-size: 12px"
                          class="smallLightHeading text-capitalize"
                        >
                          {{ assigningItem.role }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 py-4>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      x-small
                      depressed
                      dense
                      :ripple="false"
                      color="#EdEdEd"
                      style="letter-spacing: 0px"
                      ><span
                        v-if="caseDetails.animalId"
                        style="
                        font-family: sofiaProRegular;
                        color: #000;
                        font-size: 10px;
                      "
                        >{{ caseDetails.animalId.name }}
                      </span></v-btn
                    ><br />
                    <span
                      v-if="caseDetails.address"
                      style="
                        font-family: sofiaProRegular;
                        font-size: 13px;
                        color: #000;
                      "
                    >
                      {{ caseDetails.address }}
                      <!-- Chempara peak, Wayanadu wildlife Sanctuary    --> </span
                    ><br />
                    <span
                      v-if="caseDetails.create_date"
                      class="smallLightHeading"
                    >
                      {{ createDate }}
                    </span>
                  </v-flex>

                  <v-flex xs6 offset-3 pt-4>
                    <v-btn
                      block
                      small
                      :color="appColor"
                      dark
                      :ripple="false"
                      depressed
                      type="submit"
                      @click="assign('other', assigningItem._id)"
                    >
                      <span class="text-capitalize" style=""> CONTINUE </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["caseDetails", "createDate"],
  data() {
    return {
      assignDialogue1: false,
      assignDialogue2: false,
      officials: [],
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      assigningItem: {},
      rrtname: "",
    };
  },
  mounted() {
    this.getOfficials();
  },
  methods: {
    assign(assignType, officerId) {
      this.assignDialogue1 = false;
      this.assignDialogue2 = false;
      this.$emit("stepper", {
        type: "assignFunction",
        assignType: assignType,
        officerId: officerId,
      });
    },
    getOfficials() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rrt/getlistdropdown/forassign",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.officials = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    assignToRrt() {
        if (!this.rrtname) {
        this.msg = "Please Select An RRT.";
        this.showSnackBar = true;
        return;
      }
      axios({
        method: "POST",
        url: "/report/assignto",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data:{
            reportid: this.caseDetails._id,
            userid: this.rrtname,
        }
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.assignDialogue1 =false;
            this.$router
                .push({
                  path: "/officers/dashboard?id=assignedCases",
                })
                .then(() => {
                  window.location.reload();
                }); 
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>