<template>
  <div v-if="caseDetails">
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-navigation-drawer
      v-model="sideNav"
      absolute
      right
      width="350px"
      color="#FAFAFA"
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center>
          <template>
            <v-layout wrap>
              <v-flex xs1>
                <v-icon color="#0071E3">mdi-account-group</v-icon>
              </v-flex>
              <v-flex xs8>
                <span style="font-family: sofiaProMedium; font-size: 16px"
                  >Users Nearby this Conflict</span
                >
                <v-layout wrap>
                  <v-flex xs6>
                    <span
                      style="
                        color: #6e6e6e;
                        font-family: sofiaProRegular;
                        font-size: 13px;
                      "
                      >{{ users.activeUsersCount }} Active Users</span
                    >
                  </v-flex>
                  <v-flex xs6>
                    <span
                      style="
                        color: #6e6e6e;
                        font-family: sofiaProRegular;
                        font-size: 13px;
                      "
                      >{{ users.offlineUsersCount }} Offline Users</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs2
                text-right
                @click="sideNav = false"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-close</v-icon>
              </v-flex>
            </v-layout>
          </template>
          <v-layout pt-4>
            <v-layout wrap justify-start>
              <v-flex text-left class="formClass1">
                <span>Please Select the Range(2km to 50Km)</span>
              </v-flex>
              <v-flex xs12 pt-4>
                <v-slider
                  v-model="kmRange"
                  :color="appColor"
                  track-color="grey"
                  always-dirty
                  min="2"
                  max="50"
                  hide-details
                  :thumb-size="18"
                  :thumb-color="appColor"
                  thumb-label="always"
                >
                  <template v-slot:prepend>
                    <span class="formClass1"> 2Km</span>
                  </template>

                  <template v-slot:append>
                    <span class="formClass1"> 50Km</span>
                  </template>
                </v-slider>
                <!-- <v-range-slider
                  hint="2 to 50"
                  max="50"
                  min="2"
                ></v-range-slider> -->
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-layout>
          <v-layout wrap justify-center align-center>
            <v-flex xs5>
              <v-btn
                block
                small
                width="30px"
                light
                :ripple="false"
                color="#FFF"
                depressed
                rounded
              >
                <span
                  class="text-capitalize"
                  style="font-family: sofiaProMedium; font-size: 13px"
                >
                  {{ users.activeUsersCount }} Active Users
                </span>
              </v-btn>
            </v-flex>
            <v-flex xs4 offset-3>
              <v-checkbox
                v-if="users.activeUsersCount != 0"
                v-model="online"
                :ripple="false"
                label="Select All"
                dense
                color="#5CA067"
                style="font-family: sofiaProBold; font-size: 10px"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <template v-if="users.activeUsersCount > 0">
            <v-layout
              wrap
              v-for="(item, index) in users.activeUsers"
              :key="index"
              pt-3
            >
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex xs3>
                    <v-avatar size="40px">
                      <img :src="mediaURL + item.photo" />
                    </v-avatar>
                  </v-flex>
                  <v-flex xs9 text-left>
                    <span
                      style="font-family: sofiaProMedium; font-size: 14px"
                      >{{ item.fullName }}</span
                    ><br />
                    <span style="font-family: sofiaProRegular; font-size: 12px"
                      >Nearby
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
          <v-layout v-else pl-3 pt-3>
            <span style="font-family: sofiaProMedium; font-size: 13px"
              >No Active Users Found</span
            >
          </v-layout>
          <v-layout py-4>
            <v-divider></v-divider>
          </v-layout>

          <v-layout wrap>
            <v-flex xs6>
              <v-btn
                block
                small
                light
                :ripple="false"
                color="#FFF"
                depressed
                rounded
              >
                <span
                  class="text-capitalize"
                  style="font-family: sofiaProMedium; font-size: 13px"
                >
                  {{ users.offlineUsersCount }} Offline Users
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-3>
            <v-flex
              v-if="users.offlineUsersCount > 0"
              @click="showDetail"
              xs12
              text-left
            >
              <!-- <GroupAvatar
                borderColor="transparent"
                :avatars="avatars"
                
              ></GroupAvatar> -->
              <!-- <div class="avatars-group pa-3 stacked">
                <div
                  v-for="(avatar, a) in avatars"
                  :key="a"
                  class="avatars-group__item"
                > 
                  <v-avatar>
                    <img
                      :src="avatar"
                      alt="John"
                    />
                  </v-avatar>
                </div>
              </div> -->
              <div class="avatars-group pa-3 stacked">
                <div
                  v-for="(avatar, a) in limitAvatars"
                  :key="a"
                  class="avatars-group__item"
                >
                  <v-avatar>
                    <img :src="avatar" />
                  </v-avatar>
                </div>
                <div class="avatars-group__item more" v-if="avatars.length>4">
                  <v-avatar :color="appColor">
                    <!-- <v-icon dark> mdi-plus </v-icon> -->
                    <span style="color: #ffffff"> +{{ rem }} </span>
                  </v-avatar>
                </div>
              </div>
            </v-flex>
            <v-flex v-else pl-3 pt-3 text-left>
              <span style="font-family: sofiaProMedium; font-size: 13px"
                >No Offline Users Found</span
              >
            </v-flex>
            <v-flex v-if="showDetails" xs12 text-left>
              <template v-if="users.offlineUsersCount > 0">
                <v-layout
                  wrap
                  v-for="(item, index) in users.offlineUsers"
                  :key="index"
                  pt-3
                >
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs3>
                        <v-avatar size="40px">
                          <img :src="mediaURL + item.photo" />
                        </v-avatar>
                      </v-flex>
                      <v-flex xs9 text-left>
                        <span
                          style="font-family: sofiaProMedium; font-size: 14px"
                          >{{ item.fullName }}</span
                        ><br />
                        <span
                          style="font-family: sofiaProRegular; font-size: 12px"
                          >Nearby
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
              <v-layout v-else pl-3 pt-3>
                <span style="font-family: sofiaProMedium; font-size: 13px"
                  >No Offline Users Found</span
                >
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center align-center>
            <v-flex>
              <v-checkbox
                v-model="all"
                :ripple="false"
                label="Select All"
                dense
                color="#5CA067"
                style="font-family: sofiaProBold; font-size: 10px"
              ></v-checkbox>
            </v-flex>
            <v-flex>
              <v-btn
                @click="sendToPublic"
                block
                small
                light
                :ripple="false"
                :color="appColor"
                depressed
              >
                <span
                  style="
                    font-family: sofiaProMedium;
                    font-size: 13px;
                    color: #fff;
                  "
                >
                  SEND
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout align-start class="hidden-sm-and-down">
            <v-flex xs1 align-self-end>
              <v-icon @click="sideNav = false"
                >mdi-arrow-left-bold-circle-outline</v-icon
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "axios";

// import "vue-group-avatar/dist/vue-group-avatar.css";
// import { GroupAvatar } from "vue-group-avatar";
export default {
  props: ["sideNav", "caseDetails", "users", "avatars"],
  components: {
    // GroupAvatar,
  },
  data() {
    return {
      checkbox: true,
      max: 10,
      online: false,
      all: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      showDetails: false,
      kmRange: null,
      limitAvatars: [],
      rem: null,
    };
  },
  watch: {
    kmRange() {
      this.$emit("stepper", {
        type: "usersNearBy",
        kmRange: this.kmRange,
      });

      // if (this.avatars.length >= 4) {
      //   for (let i = 0; i < 4; i++) {
      //     this.limitAvatars.push(this.avatars[i]);
      //   }
      //   console.log("hi");
      // } else this.limitAvatars = this.avatars;
    },
    avatars() { 
      this.limitAvatars = [];
      if (this.avatars.length >= 4) {
        for (let i = 0; i < 4; i++) {
          this.limitAvatars.push(this.avatars[i]);
        }
        // console.log("ff=", this.limitAvatars);

        this.rem = this.avatars.length - 4;
      } else this.limitAvatars = this.avatars;
    },
  }, 
  methods: {
    showDetail() {
      if (this.showDetails) this.showDetails = false;
      else this.showDetails = true;
    },
    sideNavStepper() {
      this.$emit("stepper", {
        type: "sideNav",
        sideNav: !this.sideNav,
      });
    },

    sendToPublic() {
      this.appLoading = true;
      var sendValue;
      if (this.online) sendValue = "active";
      else if (this.all) sendValue = "all";
      if (sendValue) {
        axios({
          method: "POST",
          url: "/report/sendto/public",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            reportId: this.$route.query.id,
            sendTo: sendValue,
            kmRange: this.kmRange,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.$emit("stepper", {
                type: "sendToPublic",
                sideNav: false,
                pageResponse: response.data,
              });
            } else this.msg = response.data.msg;
            this.showSnackBar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      } else this.msg = "Please select Value";
      this.showSnackBar = true;
    },
  },
};
</script>

<style scoped>
.avatars-group.grid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
}
.avatars-group.stacked {
  display: flex;
  flex-direction: row;
  direction: ltr;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.avatars-group.stacked > * {
  margin-right: -8px;
}
.avatars-group.stacked > *:last-of-type {
  padding-right: 16px;
}
.avatars-group__item {
  cursor: default;
  transition: all 0.1s ease-out;
}
.avatars-group__item.more {
  align-items: center;
  display: flex;
}
.avatars-group__item.more:hover {
  transform: none;
}
.avatars-group__item:hover {
  transform: translateY(-4px);
  z-index: 1;
}
.avatars-group .v-avatar {
  box-shadow: 0px 0px 0px 2px #fff inset;
}
.avatars-group .v-avatar img {
  padding: 2px;
}
.avatars-group .v-avatar span {
  align-items: center;
  display: flex;
  font-size: 110%;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.1rem;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.56);
  width: inherit;
}
.v-avatar.bordered {
  box-shadow: 0px 0px 0px 2px #fff inset;
}
.v-avatar.bordered img {
  padding: 2px;
}
.v-avatar.bordered.small {
  box-shadow: 0px 0px 0px 1px #fff inset;
}
.v-avatar.bordered.small img {
  padding: 1px;
}
.presence {
  box-shadow: 0px 0px 0px 2px #fff inset;
  border-radius: 50%;
  bottom: 0px;
  display: block;
  height: 14px;
  position: absolute;
  width: 14px;
}
.v-avatar.bordered.small .presence {
  box-shadow: 0px 0px 0px 1px #fff inset;
  display: block;
  height: 8px;
  position: absolute;
  width: 8px;
}
</style>
