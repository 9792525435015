<template>
  <div>
    <v-layout wrap justify-center >
      <v-flex xs12  >
        <v-card v-viewer outlined tile height="200px"  width="100%" >
            <img :src="mediaURL + storage" height="100%" width="100%" style="object-fit: contain;" fill-height>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {},
};
</script>